.settingsSection {
  padding: 15px;
  padding-bottom: 10px;
  
}

.buttonMargin {
  margin: 0px;
  margin-right: 0px;
}

.inputRange {
  padding-right: 15px;
  padding-left: 10px;
  margin-bottom: -5px;
  margin-top: -5px;
}

.switchPanel {
  margin-top: 1px;
  margin-bottom: 1px;
  height: 20px;
}

div.edgeArea div[class*="labelLeft"] {
  margin-bottom: -2px;
  height: 35px;
}
