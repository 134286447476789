.validationBubbleBase {
  margin: 0px 5px 3px 0;
  padding: 2px 5px;
  border-radius: 2px; //$border-radius-base;
  font-size: 0.8rem;
}

.topRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.genesSelection {
  textarea.default {
    width: 555px;
    resize: vertical;
  }
  textarea.oncoprintHeatmap {
    width: 100%;
    resize: vertical;
    border-radius: 2px;
  }
  textarea {
    border-radius: 2px; // $border-radius-base;
    padding: 5px;
    &.empty {
    }
    border-color: black// $borderColor;
  }

  textarea.studyView {
    width: 200px;
    height: 30px;
    border-radius: 2px; //$border-radius-base;
    outline-offset: -2px;
    font-size: 12px;
    resize: none;
    margin: 0px 3px;
    line-height: 18px;
    padding-left: 10px;
  }

  textarea.studyViewFocus {
    height: 5em;
    font-size: 13px;
  }
}

.GeneSymbolValidator {
  cursor: default;
  display: flex;
  align-items: center;
  padding-top: 1px;
  flex-wrap: wrap; 

  button {
    line-height: 1 !important;
  }

  span.icon {
    text-align: center;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    line-height: 17px;
  }
  span.pendingMessage,
  span.oqlNotice,
  span.errorMessage {
    font-size: small;
    color: rgb(207, 60, 7); // $redError;
    font-weight: bold;
  }
  div.validBubble,
  div.invalidBubble {
    color: #fff;
    display: flex;
    align-items: center;
    @extend .validationBubbleBase;
    span.icon {
      color: #fff;
      line-height: 17px;
    }
  }
  div.invalidBubble {
    background: rgb(207, 60, 7); // $redError;
    white-space: nowrap;
  }
  div.warningBubble {
    background: rgb(230, 233, 48); // $redError;
    white-space: nowrap;
    border-radius: 15px;
    padding-right:8px;
    
    .icon {
      color: rgb(0, 0, 0);
      line-height: 15px;
      text-align: center;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  div.warningBubble2 {
    background: rgb(162, 233, 48); // $redError;
    white-space: nowrap;
    border-radius: 15px;
    padding-right:8px;
    
    .icon {
      color: rgb(0, 0, 0);
      line-height: 15px;
      text-align: center;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  div.warningBubble3 {
    background: rgb(233, 153, 48); // $redError;
    white-space: nowrap;
    border-radius: 15px;
    padding-right:8px;
    
    .icon {
      color: rgb(0, 0, 0);
      line-height: 15px;
      text-align: center;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  div.validBubble {
    background: green; // $greenSuccess;
    white-space: nowrap;
  }
  div.suggestionBubble {
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    @extend .validationBubbleBase;
    background-color: rgb(170, 168, 168); //$lightGrey;
    border-radius: 2px;
    &:hover {
      background-color: grey; // $mediumGrey;
    }
    span.icon {
      color: darkgray; // $darkGrey;
      line-height: 17px;
    }
    span.noChoiceLabel {
      font-weight: normal;
    }
    span.singleChoiceLabel {
      font-weight: bold;
    }
    span.multiChoiceLabel {
      font-weight: normal;
    }
    div:global(.Select) {
      width: 140px;
      margin-left: 4px;
      float: left;
    }
  }
 
}


