.topBar {
  div {
    > div:nth-child(2) {
      display: flex;
      margin-right: 15px;
    }

    margin-left: 1px;
    padding-right: 10px;
    padding-left: 10px;
    > div {
      > a > span {
        white-space: "pre-wrap";
        text-overflow: clip;
        line-height: 1em;
        font-size: 0.8em;
        margin-top: 3px;
      }
      > a {
        display: flex;
        flex-direction: column;
      }
    }
    justify-content: center;
    margin-top: 3px;
  }

  font-size: medium;
  //background-color: rgb(255, 0, 0);
  max-width: 1200px;
  height: 60px;
  //border: 2px solid rgb(3, 76, 233);
  margin-top: 30px;
  ul {
    height: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    color: whitesmoke;
    cursor: pointer;
    .active {
      background-color: white;
      color: black;
    }
    li {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        border-radius: 5px;
        font-size: 1px;
        padding: 10px;

        text-decoration: none;
        &:hover {
          @extend .active;
          background-color: rgb(199, 58, 58);
        }
      }
    }
  }
}
