.mainView {
  padding: 25px;
  width: 100%;
  overflow: auto;
  height: 100%;
  font-size: 17px;
}
.mainViewOuter {
  padding: 0px;
  width: 100%;
  height: 100%;
}
.help {
  margin-top: 5px;
  margin-left: -15px;
}

.submainView {
  padding: 10px;
  padding-top: 0px;
  width: 100%;
  overflow: auto;
  height: 100%;
  font-size: 17px;
}

.infoSection {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  max-width: 1200px;
}

.moduleTextInfos {
  width: 100%;
  font-size: 17px;
}

.float-right {
  float: right;
  margin: 0 0 10px 10px;
  max-width: 100%;
  height: auto;
}
