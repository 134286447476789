.mainView {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the viewport height */ 
  flex: 1;
}

.controlBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  flex-wrap: wrap; /* Allow items to wrap on small screens */
}

.controlGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping within the group */
}

.instructionText {
  font-size: 16px;
  margin-right: 10px;
  flex: 1 1 auto; /* Allow text to take up remaining space */
  word-wrap: break-word; /* Allow long words to wrap */
}

.zoomControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.zoomButton {
  font-size: 16px;
  margin-left: 10px;
  background-color: green;
  padding: 5px 10px;
  transition-duration: 0.4s;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.zoomButton:hover {
  background-color: yellow; /* Change color on hover */
  color: black;
}

.mainContent {
  flex: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: scroll;  

}

.geneTable {
  //flex: 1; /* Allow it to grow and fill available space */
  overflow: auto; /* Handle overflow if needed */
}

.transformWrapper {
  flex: 1; /* Allow it to grow and fill available space */
  overflow: auto; /* Handle overflow if needed */
}

.enrichmentTableContainer {
  display: block; /* Or use flex if you prefer */
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: fit-content;
}
.heatmapContainer {
  flex: 1;
  //overflow: auto;
  display: flex;
  justify-content: flex-start; /* Align to left */
  align-items: flex-start;     /* Align to top */
  padding: 10px;
  box-sizing: border-box;
}

.scaledWrapper {
  position: relative;
  /* minWidth and minHeight set via inline styles */
}
