.mainView {
  width: calc(100% - 40px);
  margin: 20px;
  height: calc(100% - 100px);
  max-height: 100vh;
}

.bokehChart {
  z-index: 999;
  width: 1000px;
}
