.mainView {
  padding: 25px;
  width: 100%;
  overflow: auto;
}

.infoSection {
  display: block;
  justify-content: space-between;
  max-width: 1200px;
}

.moduleTextInfos {
  width: 70%;
}


.float-right {
  float: right;
  margin: 0 0 10px 10px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  
}

.slideshow {
  height:100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;  
  float: right;
  margin: 0 20px 10px 10px;
 // margin-top: 30px;
}

.slideshow img {
  position: absolute;
  height:100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
 
  animation: slide 15s infinite;//n*t/2
  animation-iteration-count: infinite;
  object-fit: contain;
  
}

.slideshow img:first-child {
  opacity: 1;
}

@keyframes slide {
   0%,33.3%,100% { opacity: 0; }
   5%, 25% { opacity: 1; }
}

.slideshow img:nth-child(1) {
  animation-delay: 0s;
}

.slideshow img:nth-child(2) {
  animation-delay: 5s;
}

.slideshow img:nth-child(3) {
  animation-delay: 10s;
}