.mainView {
  padding: 10px;
  width: 100%;
  height: 350px;
}

.columns {
  width: 100%;
  margin-bottom: 20px;
}
