.mainView {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 15px;
}

.subItems {
  margin-top: 3px;
  width: 100%;

}