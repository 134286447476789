.mainView {
  display: block;
  //grid-template-rows: auto 1fr;
  //height: 100vh;
  width: 100%;
  padding: 10px;
  align-items: center;
  min-height: 100vh;
  justify-items: center;
  margin-top: 1em;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.parentOfMainView {
  display: flex;
  margin-left: 25vw;
  width: 50vw;
  justify-items: center;

}

.cookie_consent_container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  display: none; /* Hidden by default */
}
.consentContainer {
  margin-top: 10px;
  background: #f1e7ae;
  border: 4px dashed;
  padding: 12.5px 20px;
  box-shadow: 0 -1px 10px 0 #acabab4d;
  border-color: #0011ff;
}

.consentGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.consentBtn {
  font-size: 20px;
  //width: 3em;
  font-family: inherit;

  line-height: 20px;
  padding: 5px 10px;
  width: 120px;
  height: 40px;
  font-weight: 500;
  margin: 0 8px 0 0;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  min-height: 0;
  color: #ffffff;
  border-color: #1863dc;
  background-color: #1863dc;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s; /* Smooth transition for hover effect */
}
.consentBtn:hover {
  background-color: #2feb00; /* Slightly lighter blue on hover */
  border-color: #42e11a; /* Border color changes with background */
  color: #000000; /* Optional: Slightly lighter text color on hover */
}

.consentBtnR:hover {
  background-color: #e1841a; /* Slightly lighter blue on hover */
  border-color: #e16a1a; /* Border color changes with background */
  color: #e6e6e6; /* Optional: Slightly lighter text color on hover */
}

.consentBtnR {
  font-size: 20px;
  //width: 3em;
  font-family: inherit;
  width: 120px;
  height: 40px;
  line-height: 20px;
  padding: 3px 15px;
  font-weight: 500;
  margin: 0 10px 0 10px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  min-height: 0;
  color: #ffffff;
  border-color: #dc1818;
  background-color: #dc2f18;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s; /* Smooth transition for hover effect */
}
.consentTitle {
  color: #212121;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  overflow-wrap: break-word;
  font-family: "Poppins", sans-serif;
}

.infoSection {
  display: flex;
  width: 100%;
  max-width: 880px;
  font-size: large;
  line-height: 1.3;
  margin-bottom: 30px;
  //align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}

.moduleTextInfos {
  width: 50%;
}

.float-right {
  margin: 0 0 10px 10px;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  object-fit: scale-down;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.glass-panel2 {
  color: #fff;
  //margin: 10px auto;
  background-color: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.1);
  //width: 100%;
  height: 30%;
  border-radius: 15px;
  padding: 32px;
  backdrop-filter: blur(10px);
  //max-width: 800px;
  //background: url('../../../public/images/background4.png') no-repeat center center fixed;
  -webkit-background-size: 80%;
  -moz-background-size: 80%;
  -o-background-size: 80%;
  background-size: 80%;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.glass-button2 {
  //display: inline-block;

  padding: 24px 32px;
  border: 0;
  font-weight: 900;
  text-decoration: none;
  border-radius: 15px;
  background-color: rgba(199, 182, 34, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
  margin: 5px;
  margin-top: 10px;
}

.glass-button2:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.glass-button:hover {
  color: #fff;
  background-color: #e76a16;
  box-shadow: inset 0 0 0 2px #f7550a;
}


.glass-button {
  width: 100%;
  max-width: 800px;
  font-family: Lato, sans-serif;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color, border-color, color, box-shadow, filter;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  //letter-spacing: 2px;
  min-width: 160px;
  //text-transform: uppercase;
  white-space: normal;
  //font-weight: 700;
  text-align: center;
  padding: 10px 10px 10px;
  color: #000000;
  box-shadow: inset 0 0 0 3px #616467;
  background-color: transparent;
  height: 48px;
  //margin: 2px;
  //margin-top: 2px;
  margin-bottom: 2px;
}

/* Extra styles for demo */
/* Article:  https://superdevresources.com/glassmorphism-ui-css/
.background1 {
  background: url(https://thumbs.dreamstime.com/b/blur-glass-background-abstract-blurred-colorful-40536095.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(122, 120, 120);
}
 

h1, h1 a {
  min-height: 120px;
  width: 90%;
  max-width: 700px;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto;
  text-decoration: none;
  color: #fff;
  padding-top: 60px;
  color: rgba(255,255,255,0.8);
}

p {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 32px;
  color: rgba(255,255,255,0.6);
}*/
